import { AppLogin } from 'pages/Login';
import { NoAccess } from 'pages/NoAccess/NoAccess';
import { paths } from './paths';

import { Pages } from 'pages/Pages';
import { Dashboard } from 'pages/Dashboard';

export const routes = [
  {
    path: paths.client.LOGIN,
    exact: true,
    component: AppLogin,
    guest: true,
  },
  {
    path: paths.client.NOACCESS,
    exact: true,
    component: NoAccess,
    guest: true,
  },
  {
    path: paths.client.BASE,
    exact: false,
    component: Pages,
    private: true,
    guest: false,
    routes: [
      {
        path: paths.client.BASE,
        exact: true,
        component: Dashboard,
        private: true,
        guest: false,
      },
    ],
  },
];
