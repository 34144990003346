import React, { useState } from 'react';

import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme as createMuiTheme,
} from '@mui/material/styles';

const createTheme = (): any => {
  return createMuiTheme({
    palette: {
      common: {
        black: '#000',
        white: '#fff',
      },
      background: { paper: '#fff', default: '#E8E3D0' },
      primary: {
        light: '#057df6',
        main: '#006ad4',
        dark: '#0054a8',
        contrastText: '#fff',
      },

      secondary: {
        light: '#f5dd78',
        main: '#BED0B6',
        dark: '#c8b35c',
        contrastText: '#000',
      },

      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff',
      },

      success: {
        light: '#59b3af',
        main: '#218380',
        dark: '#005654',
        contrastText: '#fff',
      },

      info: {
        main: '#0288d1',
        light: '#03a9f4',
        dark: '#01579b',
        contrastText: '#fff',
      },

      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.54)',
        disabled: 'rgba(0, 0, 0, 0.38)',
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&:invalid': {
              // border: 'red solid 1px',
              color: 'red',
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          marginDense: {
            marginTop: '0px',
            marginBottom: '0px',
          },
          root: { backgroundColor: 'white' },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '0.8rem !important',
          },
        },
      },
    },
    // components: {
    //   MuiTab: {
    //     styleOverrides: {
    //       root: {
    //         color: '#000000',
    //       },
    //       selected: {
    //         color: '#000000',
    //       },
    //     },
    //   },
    // },
    // TODO: overrides
    // components: {
    //   MuiTableBody: {
    //     styleOverrides: {
    //       root: {
    //         '& > :nth-of-type(1n)': {
    //           backgroundColor: '#E8E3D0',
    //         },
    //         '& > :nth-of-type(2n)': {
    //           backgroundColor: '#ffffff',
    //         },
    //       },
    //     },
    //   },
    // },
  });
};

const Theme = React.forwardRef(function Theme(props: any, ref: any) {
  const [theme] = useState(createTheme());
  const { component: Component = 'div', ...other } = props;

  // console log current theme
  // useEffect(() => {
  //   console.log('Theme', theme);
  // }, [theme]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Component ref={ref} {...other} />
    </MuiThemeProvider>
  );
});

export default Theme;
