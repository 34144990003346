import { MAINTENANCE_PERIOD_URL } from 'constants/config';
import { useState, useEffect, useMemo, useCallback } from 'react';

export const useMaintenanceMessage = () => {
  const [period, setPeriod] = useState<any>();

  const getMaintenanceData = useCallback(async () => {
    if (MAINTENANCE_PERIOD_URL) {
      try {
        let response = await fetch(MAINTENANCE_PERIOD_URL);
        let responseJson = await response.json();
        return responseJson;
      } catch {}
    }
  }, []);

  useEffect(() => {
    getMaintenanceData()
      .then((response: any) => {
        if (response) {
          const period = response.schedule?.find((period: any) => {
            const dateFromTs = Date.parse(period.from);
            const dateToTs = Date.parse(period.to);
            const nowTs = Date.now();
            if (dateFromTs - 24 * 60 * 60 * 1000 <= nowTs && dateToTs >= nowTs) {
              return true;
            }
            return false;
          });
          if (period) {
            setPeriod({ dateFromTs: Date.parse(period.from), dateToTs: Date.parse(period.to) });
          } else {
            setPeriod(undefined);
          }
        }
      })
      .catch();
  }, [getMaintenanceData]);

  const maintenanceText = useMemo<string | undefined>(() => {
    if (!period) {
      return undefined;
    }
    return (
      'Maintenance is scheduled for ' +
      new Date(period.dateFromTs).toLocaleString() +
      ' to ' +
      new Date(period.dateToTs).toLocaleString() +
      ' Local Time'
    );
  }, [period]);

  return { maintenanceText, maintenancePeriod: period };
};
