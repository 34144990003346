import { useLazyQuery } from '@apollo/client';
import { usersList } from 'graphql/forecast/types/usersList';
import { GET_USERS } from 'graphql/forecast/users';
import { useCallback, useEffect, useState } from 'react';
import { useMsalAccount } from './msalAccount';

export interface ILoggedInUserData {
  oid: string;
  email: string;
  name: string;
}

export interface ICurrentUser extends ILoggedInUserData {
  id: string;
}

const maxItems = 10;
export const useCurrentUser = () => {
  const [users, setUsers] = useState<ICurrentUser[]>();
  const [loggedInUserData, setLoggedInUserData] = useState<ILoggedInUserData | undefined>();

  const { msalAccount: currentAccount } = useMsalAccount();

  const [
    loadUsers,
    {
      data: dataUsers,
      loading: loadingUsers,
      refetch: refetchUsers,
      called: calledUsers,
      error: errorUsers,
    },
  ] = useLazyQuery<usersList>(GET_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (currentAccount) {
      const { accountIdentifier: oid, userName: email, name } = currentAccount;
      setLoggedInUserData({
        oid,
        email,
        name,
      });
    } else {
      setLoggedInUserData(undefined);
    }
  }, [currentAccount]);

  useEffect(() => {
    if (dataUsers?.users && !loadingUsers && currentAccount) {
      const { accountIdentifier: oid, userName: email } = currentAccount;
      setUsers(
        dataUsers.users.map(({ name, id }) => ({
          id,
          name,
          oid,
          email,
        }))
      );
    }
  }, [dataUsers, loadingUsers, currentAccount]);

  const load = useCallback(() => {
    const { accountIdentifier: oid, userName: email } = currentAccount;
    const variables = {
      take: maxItems,
      skip: 0,
      filter: {
        oid,
        emailContains: email,
        isActive: true,
      },
    };

    if (calledUsers) {
      refetchUsers!(variables);
    } else {
      loadUsers({ variables });
    }
  }, [loadUsers, refetchUsers, calledUsers, currentAccount]);

  useEffect(() => {
    if (!!currentAccount?.userName) {
      load();
    }
  }, [currentAccount, load]);

  return {
    users,
    loadingUsers,
    errorUsers,
    load,
    currentAccount,
    loggedInUserData,
  };
};
