/* eslint-disable camelcase */
import React, { FC, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  AppBar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { Home as HomeIcon, ExitToApp as ExitToAppIcon, ListAltRounded } from '@mui/icons-material';

import { grey } from '@mui/material/colors';

import './index.scss';

import { useComponentContext as useFormChangedDialogContext } from 'components/FormChangedDialog/FormChangedDialogContext';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { SvgLogo } from 'images/SvgLogo';
import { Box } from '@mui/system';
import { FileDarkIcon } from 'images/icons/FileDarkIcon';

import cn from 'classnames';
import { paths } from 'constants/paths';
import { useCurrentUser } from 'hooks/currentUserHook';
import UserInitials from 'components/UserInitials';
import { getMsal1Account, msalLogout } from 'authorization/auth-utils-msal2';

const Header: FC<any> = () => {
  const { users, currentAccount } = useCurrentUser();
  const { pathname } = useLocation();
  const { checkAndProceed } = useFormChangedDialogContext();

  const [userDrawerOpen, setUserDrawerOpen] = useState(false);
  const [autoLogoffMessageShown, setAutoLogoffMessageShown] = useState(false);

  const [loggedUserAccount, setLoggedUserAccount] = useState(currentAccount);
  const [userEmail, setUserEmail] = useState('');

  const history = useHistory();

  const gotoHome = useCallback((): void => {
    history.replace('/');
  }, [history]);

  const openUserMenu = useCallback((): void => {
    setUserDrawerOpen(true);
  }, []);

  const closeUserMenu = useCallback((): void => {
    setUserDrawerOpen(false);
  }, []);

  const logout = useCallback((): void => {
    msalLogout();
  }, []);

  const gotoAssets = useCallback((): void => {
    history.push(paths.client.BASE);
  }, [history]);

  const firstTab = useMemo(() => {
    return pathname.split('/')[1];
  }, [pathname]);

  const tabReportsActive = useMemo(() => ['reports'].includes(firstTab), [firstTab]);

  const tabAssetsActive = useMemo(
    () => ['assets', 'asset', 'new-asset', ''].includes(firstTab) && !tabReportsActive,
    [firstTab, tabReportsActive]
  );

  let autoLogoutTimeoutIdRef = useRef(-1);
  let autoLogoutMessageTimeoutIdRef = useRef(-1);

  const displayAutoLogoutWarning = useCallback(
    (location: any) => {
      clearTimeout(autoLogoutMessageTimeoutIdRef.current);
      clearTimeout(autoLogoutTimeoutIdRef.current);
      setAutoLogoffMessageShown(false);

      if (location && location.pathname && location.pathname.toLowerCase() === '/login') {
        setAutoLogoffMessageShown(false);
      } else {
        autoLogoutMessageTimeoutIdRef.current = window.setTimeout(() => {
          setAutoLogoffMessageShown(true);

          autoLogoutTimeoutIdRef.current = window.setTimeout(() => {
            logout();
          }, 1000 * 60 * 5); // logout 5 minutes after message is shown
        }, 1000 * 60 * 235); // show message after 235 minutes (4 hours - 5 minutes) of inactivity
      }
    },
    [logout]
  );

  const getLoggedInUserEmail = useCallback(
    (): any => (
      <Typography
        variant="subtitle2"
        color="secondary"
        style={{ display: 'inline-block', padding: '1rem' }}
      >
        {userEmail}
      </Typography>
    ),
    [userEmail]
  );

  useEffect((): void => {
    // #Zoran, uncomment if auto renew token is wanted
    // Token expiry is set to 4 hours, so it is not needed
    // setInterval(() => renewToken(), 1000 * 120); // try to auto renew token every 2 minutes

    displayAutoLogoutWarning(history.location);
  }, [displayAutoLogoutWarning, history.location]);

  useEffect((): void => {
    history.listen((location: any) => {
      displayAutoLogoutWarning(location);
    });
  }, [history, displayAutoLogoutWarning]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect((): void => {
    const accountEmail: string =
      (loggedUserAccount?.idToken?.email as string | undefined) ||
      loggedUserAccount?.idToken?.preferred_username ||
      '';
    setUserEmail(accountEmail);
  }, [loggedUserAccount]);

  useEffect((): void => {
    setLoggedUserAccount(getMsal1Account());
  }, [currentAccount]);

  const getUserInfoButton = useCallback(
    (): JSX.Element => <UserInitials {...getMsal1Account()} />,
    []
  );

  const AnonymousUserHeader = useMemo(
    () => (
      <div style={{ paddingRight: '0px', paddingLeft: '16px', borderTop: '1px solid #b2b2b230' }}>
        <div className="header-section-inline">
          <div>
            <span id="header-logo">
              <SvgLogo />
            </span>
          </div>

          <div className="header-section-inline">
            <div className="header-section-inline" style={{ paddingBottom: '2px' }}>
              <ListAltRounded fontSize="small" style={{ color: grey[900] }} /> &nbsp;{' '}
            </div>
            <div className="header-section-inline">
              <Typography variant="subtitle2" color="inherit" noWrap style={{ color: grey[900] }}>
                Revenue Forecast
              </Typography>
            </div>
          </div>
        </div>
      </div>
    ),
    []
  );

  const RegisteredUserHeader = useMemo(
    () => (
      <>
        <Box order={{ xs: 1, sm: 1 }}>
          <div
            className="header-separator"
            style={{ paddingRight: '0px', paddingLeft: '16px', borderTop: '1px solid #b2b2b230' }}
          >
            <div className="header-section-inline">
              <div
                onClick={debounce(checkAndProceed!(gotoHome), DEBOUNCE_TIMEOUT)}
                style={{ cursor: 'pointer' }}
              >
                <span id="header-logo">
                  <SvgLogo />
                </span>
              </div>
            </div>
          </div>
        </Box>
        <Box order={{ xs: 3, sm: 2 }} style={{}}>
          <div
            style={{
              ...{ display: 'flex', minHeight: '62px', borderTop: '1px solid #b2b2b230' },
              ...(matches ? undefined : { width: '100%' }),
            }}
          >
            <div className="header-section header-separator">
              <Typography
                variant="subtitle2"
                color="inherit"
                noWrap
                style={tabAssetsActive ? { color: grey[900] } : { color: '#006ad4' }}
              >
                <div
                  className="header-section-inline"
                  onClick={debounce(checkAndProceed!(gotoAssets), DEBOUNCE_TIMEOUT)}
                  style={{ cursor: 'pointer' }}
                >
                  <FileDarkIcon
                    className={cn(
                      tabAssetsActive ? 'actionIconActive' : 'actionIconInactive',
                      'fileIcon'
                    )}
                  />{' '}
                  <Box component="div" display={{ xs: 'inline' }}>
                    <div className="header-action">&nbsp;Revenue Forecast</div>
                  </Box>
                </div>
              </Typography>
            </div>
          </div>
        </Box>

        <Box
          order={{ xs: 2, sm: 3 }}
          style={{ flexGrow: 1, paddingRight: '1rem', borderTop: '1px solid #b2b2b230' }}
        >
          <div
            style={{
              textAlign: 'right',
              cursor: 'pointer',
              minHeight: '62px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            onClick={debounce(openUserMenu, DEBOUNCE_TIMEOUT)}
          >
            <Box component="div" display="inline">
              {getLoggedInUserEmail()}
            </Box>
            <Box component="div" display="inline">
              {getUserInfoButton()}
            </Box>
          </div>
        </Box>
      </>
    ),
    [
      checkAndProceed,
      getLoggedInUserEmail,
      getUserInfoButton,
      gotoAssets,

      matches,
      tabAssetsActive,

      gotoHome,
      openUserMenu,
    ]
  );

  const isLoggedIn = useMemo(() => {
    return currentAccount || users;
  }, [currentAccount, users]);

  return (
    <AppBar position="sticky" elevation={3} style={{ backgroundColor: grey[50] }}>
      <Drawer anchor="right" open={userDrawerOpen} onClose={closeUserMenu}>
        <List style={{ width: 250 }}>
          <ListItem
            button
            key="home"
            onClick={debounce(checkAndProceed!(gotoHome), DEBOUNCE_TIMEOUT)}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </List>

        <Divider />

        <List>
          <ListItem
            button
            key="logout"
            onClick={debounce(checkAndProceed!(logout), DEBOUNCE_TIMEOUT)}
          >
            <ListItemIcon>
              <div style={{ paddingLeft: '2px' }}>
                <ExitToAppIcon />
              </div>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {!isLoggedIn ? AnonymousUserHeader : RegisteredUserHeader}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="You will be logged out in 5 minutes. Refresh page to cancel."
        open={autoLogoffMessageShown}
      />
    </AppBar>
  );
};

export default Header;
