const BASE = '/';
const DASHBOARD = '/';
const LOGIN = '/login';
const NOACCESS = '/access-denied';

const clientPathConst = {
  BASE,
  LOGIN,
  DASHBOARD,
  NOACCESS,
};

export default clientPathConst;
